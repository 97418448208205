<template>
  <div>
    <b-card>
      <filter-card :get-data="getDataList" />
    </b-card>
    <b-card no-body>
      <b-card-body class="d-flex justify-content-between">
        <b-button
          variant="warning"
          :to="$route.path + '/custom-add'"
        >
          Özel Ürün Ekle
        </b-button>
        <b-button
          variant="primary"
          :to="$route.path + '/pending'"
          :disabled="pending === 0 || pendingLoading"
        >
          <template v-if="!pendingLoading">
            <FeatherIcon icon="EyeIcon" /> {{ pending }} Bekleyen Ürün
          </template>
          <template v-else>
            <div class="d-flex align-items-center">
              <b-spinner
                small
                class="mr-1"
              /> Kontrol Ediliyor...
            </div>
          </template>
        </b-button>
      </b-card-body>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(pcode)="data">
          <div class="d-flex">
            <div class="mr-1">
              <b-avatar
                v-if="data.item.image"
                :src=" baseURL + '/media/products/' + data.item.image"
                size="80"
              />
              <b-avatar
                v-else
                variant="primary"
              >
                <FeatherIcon icon="CameraIcon" />
              </b-avatar>
            </div>
            <div>
              <div class="font-weight-bolder text-primary">
                {{ data.item.pcode }}
              </div>
              <div class="font-weight-bold text-italic font-small-2 text-muted">
                {{ data.item.construction }}
              </div>
              <div class="font-small-2 width-400 mt-1">
                <div
                  v-if="data.item.tests.asinma"
                  class="border-bottom d-flex justify-content-between"
                >
                  <span class="text-body font-weight-bold">Martindale / Cycle: </span>
                  <span>{{ data.item.tests.asinma | toNumber }}</span>
                </div>
                <div
                  v-if="data.item.tests.asinma_sonuc_text"
                  class="border-bottom d-flex justify-content-between"
                >
                  <span class="text-body font-weight-bold">Kopuş: </span>
                  <span>{{ data.item.tests.asinma_sonuc_text }}</span>
                </div>
                <div
                  v-if="data.item.tests.pilling"
                  class="border-bottom d-flex justify-content-between"
                >
                  <span class="text-body font-weight-bold">Pilling / Cycle: </span>
                  <span>{{ data.item.tests.pilling }}</span>
                </div>
                <div
                  v-if="data.item.tests.pilling_sonuc"
                  class="border-bottom d-flex justify-content-between"
                >
                  <span class="text-body font-weight-bold">Pilling Değeri: </span>
                  <span>{{ data.item.tests.pilling_sonuc }}</span>
                </div>
                <div
                  v-if="data.item.tests.rh_kuru"
                  class="border-bottom d-flex justify-content-between"
                >
                  <span class="text-body font-weight-bold">Renk Haslığı Değeri / Kuru: </span>
                  <span>{{ data.item.tests.rh_kuru }}</span>
                </div>
                <div
                  v-if="data.item.tests.rh_yas"
                  class="border-bottom d-flex justify-content-between"
                >
                  <span class="text-body font-weight-bold">Renk Haslığı Değeri / Yaş: </span>
                  <span>{{ data.item.tests.rh_yas }}</span>
                </div>
                <div
                  v-if="data.item.tests.hav_kaybi"
                  class="border-bottom d-flex justify-content-between"
                >
                  <span class="text-body font-weight-bold">Hav Kaybı Başlangıç Değeri: </span>
                  <span>{{ data.item.tests.hav_kaybi }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #cell(width)="data">
          {{ data.item.width | toNumber }}
        </template>
        <template #cell(weight)="data">
          {{ data.item.weight | toNumber }}
        </template>
        <template #cell(control)="data">
          <div class="text-right">
            <list-buttons
              :edit-action="$route.path + '/edit/' + data.item.id"
              :delete-action="removeData"
              :data-id="data.item.id"
            />
          </div>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BButton, BCardFooter, BSpinner, BAvatar,
} from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListButtons.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FilterCard from '@/views/Admin/Products/Index/FilterCard.vue'

const tableName = 'products'
export default {
  name: 'Index',
  components: {
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BSpinner,
    BAvatar,
    ListButtons,
    FilterCard,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      pendingLoading: true,
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'pcode',
          label: 'ÜRÜN KODU',
        },
        {
          key: 'product_quality',
          label: 'KALİTE ADI',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'width',
          label: 'En',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'weight',
          label: 'Gramaj',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.pcode AS pcode`,
          `${tableName}.width AS width`,
          `${tableName}.weight AS weight`,
          `${tableName}.construction AS construction`,
          'product_qualities.title AS product_quality',
        ],
        limit: this.$store.state.app.perPage,
        start: 0,
        showTests: true,
      },
    }
  },
  computed: {
    pending() {
      return this.$store.getters['products/pending']
    },
    dataList() {
      return this.$store.getters['products/dataList']
    },
    dataCounts() {
      return this.$store.getters['products/dataCounts']
    },
    saveData() {
      return this.$store.getters['products/dataSaveStatus']
    },
    filterParams() {
      return this.$store.getters['products/filterParams']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getPending()
    this.getDataList()
    this.getFilterData()
  },
  methods: {
    getFilterData() {
      this.$store.dispatch('products/getFilterData')
    },
    getPending() {
      this.$store.dispatch('products/getPending')
        .then(response => {
          if (response) {
            this.pendingLoading = false
          }
        })
    },
    getDataList() {
      const where = {}
      const like = {}
      if (this.filterParams.pcode) {
        like['products.pcode'] = this.filterParams.pcode
      }
      if (this.filterParams.id_product_qualities) {
        where['products.id_product_qualities'] = this.filterParams.id_product_qualities
      }
      if (this.filterParams.min_weight) {
        where['products.weight >='] = this.filterParams.min_weight
      }
      if (this.filterParams.max_weight) {
        where['products.weight <='] = this.filterParams.max_weight
      }
      if (this.filterParams.asinma_min) {
        where['product_tests.asinma >='] = this.filterParams.asinma_min
      }
      if (this.filterParams.asinma_max) {
        where['product_tests.asinma <='] = this.filterParams.asinma_max
      }
      if (this.filterParams.asinma_sonuc) {
        where['product_tests.asinma_sonuc'] = this.filterParams.asinma_sonuc
      }
      if (this.filterParams.pilling_min) {
        where['product_tests.pilling >='] = this.filterParams.pilling_min
      }
      if (this.filterParams.pilling_max) {
        where['product_tests.pilling <='] = this.filterParams.pilling_max
      }
      if (this.filterParams.pilling_sonuc) {
        where['product_tests.pilling_sonuc'] = this.filterParams.pilling_sonuc
      }
      if (this.filterParams.rh_kuru) {
        where['product_tests.rh_kuru'] = this.filterParams.rh_kuru
      }
      if (this.filterParams.rh_yas) {
        where['product_tests.rh_yas'] = this.filterParams.rh_yas
      }
      if (this.filterParams.hav_kaybi) {
        where['product_tests.hav_kaybi'] = this.filterParams.hav_kaybi
      }
      if (where) {
        this.dataQuery.where = where
      } else {
        this.dataQuery.where = {}
      }
      if (like) {
        this.dataQuery.like = like
      } else {
        this.dataQuery.like = {}
      }
      this.$store.dispatch('products/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('products/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
